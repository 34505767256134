<template>
  <div class="view-box columns">
    <conversation-header :chat="currentChat" />
    <email-subject-header v-if="showSubjectHeader" />
    <div v-if="showBanner" class="banner">
      <woot-banner
        variant="error"
        :href="$t('DOCUMENTS.24HOURPOLICY')"
        :link-msg="$t('CONVERSATION.KNOW_MORE')"
        :title="
          $t('CONVERSATION.REPLY_WINDOW', { duration: replyWindowDuration })
        "
        :custom-style="{ background: '#faf9f5', 'justify-content': 'center' }"
      />
    </div>
    <div v-if="isOptChatOut" class="banner messenger-policy--info">
      <span>
        <a
          :href="$t('DOCUMENTS.OPTCHATOUT')"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          {{ $t('CONVERSATION.OPT_OUT') }}
        </a>
      </span>
    </div>

    <div v-if="isATweet" class="banner">
      <span v-if="!selectedTweetId">
        {{ $t('CONVERSATION.LAST_INCOMING_TWEET') }}
      </span>
      <span v-else>
        {{ $t('CONVERSATION.REPLYING_TO') }}
        {{ selectedTweet }}
      </span>
      <button
        v-if="selectedTweetId"
        class="banner-close-button"
        @click="removeTweetSelection"
      >
        <i v-tooltip="$t('CONVERSATION.REMOVE_SELECTION')" class="ion-close" />
      </button>
    </div>

    <ul
      class="conversation-panel flex-column flex-justify--between custom-scroll"
    >
      <div
        class="conversations"
        :class="{ 'conversations--email': isAnEmailChannel }"
      >
        <transition name="slide-up">
          <li v-if="shouldShowSpinner" class="spinner--container">
            <span class="spinner message" />
          </li>
        </transition>
        <social-post
          v-if="isASocialPost"
          :media-data="currentChat.additional_attributes.media_data"
          :type="currentChat.identifier"
          :data="currentChat"
          :get-reply-data="getReplyData"
        />
        <message
          v-for="(message, index) in getReadMessages"
          :key="message.id"
          :index="index"
          :inbox="inbox"
          :channel="currentChat.meta.channel"
          :data="message"
          :is-an-email="isAnEmailChannel"
          :is-latest-email="
            isAnEmailChannel && message.id === latestEmailMessageId
          "
          :in-reply-data-dep="getReplyDataDep(message)"
          :get-reply-data="getReplyData(message)"
          :is-a-tweet="isATweet"
          :is-a-post="isASocialPost"
          :is-reply-box-open="showReplyBox"
          :next-message="getReadMessages[index + 1]"
          @onReplyEmail="onReply"
          @onReplyAllEmail="onReplyToAll"
          @onForwardEmail="onForwardEmail"
          @onPrivateReply="onPrivateReply"
        />
        <li v-if="getUnreadCount != 0" id="unread_toast" class="unread--toast">
          <span class="text-uppercase lime-card-1dp">
            {{ getUnreadCount }}
            {{
              getUnreadCount > 1
                ? $t('CONVERSATION.UNREAD_MESSAGES')
                : $t('CONVERSATION.UNREAD_MESSAGE')
            }}
          </span>
        </li>
        <message
          v-for="(message, index) in getUnReadMessages"
          :key="message.id"
          :index="index"
          :inbox="inbox"
          :channel="currentChat.meta.channel"
          :data="message"
          :is-an-email="isAnEmailChannel"
          :is-latest-email="
            isAnEmailChannel && message.id === latestEmailMessageId
          "
          :in-reply-data-dep="getReplyDataDep(message)"
          :get-reply-data="getReplyData(message)"
          :is-a-tweet="isATweet"
          :is-a-post="isASocialPost"
          :is-reply-box-open="showReplyBox"
          :next-message="getUnReadMessages[index + 1]"
          @onReplyEmail="onReply"
          @onReplyAllEmail="onReplyToAll"
          @onForwardEmail="onForwardEmail"
          @onPrivateReply="onPrivateReply"
        />
        <li v-if="isAnyoneTyping" class="left">
          <div class="typing-indicator">
            {{ typingUserNames }}
            <img
              class="gif"
              src="~dashboard/assets/images/typing.gif"
              alt="Someone is typing"
            />
          </div>
        </li>
      </div>
      <transition key="reply-box" name="slide-fade">
        <div v-if="isAnEmailChannel && showReplyBox">
          <ReplyBox
            ref="replyBox"
            :is-an-email="isAnEmailChannel"
            :status="currentChat.status"
            :conversation-id="currentChat.id"
            :assignee="currentChat.meta.assignee"
            :disable-template="disableTemplateMode"
            :in-reply-to="selectedTweetId"
            :reply-state="replyShowState"
            :is-a-post="isASocialPost"
            :show-banner="showBanner"
            :email-forwarding-mode="emailForwardingMode"
            @scrollToMessage="scrollToBottom"
            @toggleReply="toggleReplySelection"
            @onCloseReplyBox="
              () => {
                showReplyBox = false;
                privateReplyMode = false;
              }
            "
          />
        </div>
      </transition>
    </ul>

    <div class="conversation-footer">
      <button
        v-if="showScrollButton"
        class="scroll-button"
        @click="scrollToBottom"
      >
        <icons name="chevronDown" color="grey" size="semimedium" />
      </button>
      <ReplyBox
        v-if="!isAnEmailChannel"
        ref="replyBox"
        :status="currentChat.status"
        :conversation-id="currentChat.id"
        :assignee="currentChat.meta.assignee"
        :disable-template="disableTemplateMode"
        :in-reply-to="selectedTweetId"
        :get-reply-data="getReplyData(selectedMessageForReply)"
        :reply-state="replyShowState"
        :is-a-post="isASocialPost"
        :show-banner="showBanner"
        @scrollToMessage="scrollToBottom"
        @toggleReply="toggleReplySelection"
      />
    </div>
    <private-reply-modal
      v-if="showPrivateReplyModal"
      :type="currentChat.identifier"
      :data="currentChat"
      :message="selectedMessageforPrivateReply"
      @togglePrivateReply="onTogglePrivateReply"
    />
  </div>
</template>

<script>
/* eslint-disable dot-notation */
import { mapGetters } from 'vuex';

import ConversationHeader from './ConversationHeader';
import EmailSubjectHeader from './EmailSubjectHeader';
import ReplyBox from './ReplyBox';
import Message from './Message';
import SocialPost from './bubble/SocialPost';
import PrivateReplyModal from 'dashboard/components/widgets/modal/PrivateReplyModal';
import { getTypingUsersText } from '../../../helper/commons';
import { BUS_EVENTS } from 'shared/constants/busEvents';

import conversationMixin from '../../../mixins/conversations';
import inboxMixin from 'shared/mixins/inboxMixin';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import alertMixin from 'shared/mixins/alertMixin';

import {
  MESSAGE_TYPE,
  MESSAGE_SENDER_TYPE,
  MESSAGE_CONTENT_TYPE,
} from 'shared/constants/messages';
import { retryUntilSuccess, debounce } from 'dashboard/helper/utils';

var TurndownService = require('turndown').default;
var turndownService = new TurndownService();

const OPTIONS = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export default {
  components: {
    ConversationHeader,
    EmailSubjectHeader,
    Message,
    ReplyBox,
    SocialPost,
    PrivateReplyModal,
  },
  mixins: [conversationMixin, inboxMixin, messageFormatterMixin, alertMixin],
  data() {
    return {
      showReplyBox: false,
      showPrivateReplyModal: false,
      privateReplyMode: false,
      emailForwardingMode: false,
      isLoadingPrevious: true,
      heightBeforeLoad: null,
      conversationPanel: null,
      selectedTweetId: null,
      selectedMessageForReply: {},
      selectedMessageforPrivateReply: {},
      replyShowState: false,
      showScrollButton: false,
      lastScrollPosition: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUserId: 'getCurrentUserID',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      listLoadingStatus: 'getAllMessagesLoaded',
    }),
    initiatorEmailId() {
      return this.currentChat.meta.sender.email;
    },
    mailSubject() {
      const {
        additional_attributes: { mail_subject },
      } = this.currentChat;
      return mail_subject || '------no subject------';
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    showSubjectHeader() {
      return this.isAnEmailChannel;
    },
    inboxInfo() {
      const stateInbox = this.$store.getters['inboxes/getInbox'](
        this.currentChat.inbox_id
      );

      return stateInbox;
    },
    showBanner() {
      return !this.currentChat.can_reply;
    },
    typingUsersList() {
      const userList = this.$store.getters[
        'conversationTypingStatus/getUserList'
      ](this.currentChat.id);
      return userList;
    },
    isAnyoneTyping() {
      const userList = this.typingUsersList;
      return userList.length !== 0;
    },
    typingUserNames() {
      const userList = this.typingUsersList;

      if (this.isAnyoneTyping) {
        const userListAsName = getTypingUsersText(userList);
        return userListAsName;
      }

      return '';
    },
    getMessages() {
      const [chat] = this.allConversations.filter(
        c => c.id === this.currentChat.id
      );
      return chat;
    },
    getReadMessages() {
      const chat = this.getMessages;
      return chat === undefined ? null : this.readMessages(chat);
    },
    getUnreadCount() {
      return this.getMessages?.unread_count || 0;
    },
    getUnReadMessages() {
      const chat = this.getMessages;
      let x = chat === undefined ? null : this.unReadMessages(chat);
      return x;
    },
    shouldShowSpinner() {
      return !this.listLoadingStatus && this.isLoadingPrevious;
    },
    shouldLoadMoreChats() {
      return !this.listLoadingStatus && !this.isLoadingPrevious;
    },
    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },
    isATweet() {
      return this.conversationType === 'tweet';
    },
    isASocialPost() {
      return this.isAFacebookComment || this.isInstagramComment;
    },
    selectedTweet() {
      if (this.selectedTweetId) {
        const { messages = [] } = this.getMessages;
        const [selectedMessage = {}] = messages.filter(
          message => message.id === this.selectedTweetId
        );
        return selectedMessage.content || '';
      }
      return '';
    },
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta.sender.id
      );
    },
    isOptChatOut() {
      return !this.currentContact?.opt_in || false;
    },
    disableTemplateMode() {
      if (this.showBanner && (this.isAFacebookInbox || this.isAnInstagramInbox))
        return true;
      return this.isOptChatOut;
    },
    latestEmail() {
      if (this.latestEmailMessageId === -1) return {};
      return this.getMessages.messages.filter(
        message => message.id === this.latestEmailMessageId
      )[0];
    },
    latestEmailMessageId() {
      const messages = this.getMessages.messages;

      for (let i = messages.length - 1; i >= 0; i -= 1)
        if (
          !messages[i].private &&
          [0, 1, 9, 10].includes(messages[i].message_type)
        )
          return messages[i].id;

      return -1;
    },
    replyWindowDuration() {
      if (this.inbox.channel_type === 'Channel::FacebookPage') return '7 days';

      return '24 hours';
    },
  },
  watch: {
    currentChat(newChat, oldChat) {
      this.showScrollButton = false;
      if (newChat.id === oldChat.id) return;

      this.openDraft();

      this.selectedTweetId = null;
      this.selectedMessageForReply = {};
      this.selectedMessageforPrivateReply = {};
      this.toggleReplySelection(false);
    },
  },
  created() {
    bus.$on(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
    bus.$on('openReplyBox', e => {
      const { message, productImage } = e;

      this.showReplyBox = true;
      if (productImage)
        this.prepareAttachments({ data_url: productImage.split('?')[0] }).then(
          res => {
            this.$nextTick(() => {
              this.$refs.replyBox.clearAttachments();
              this.$refs.replyBox.message = message;
              this.$nextTick(() => this.$refs.replyBox.uploadAttachments(res));
            });
          }
        );
      else {
        this.$nextTick(() => {
          this.$refs.replyBox.clearAttachments();
          this.$refs.replyBox.message = message;
        });
      }
    });

    bus.$on(BUS_EVENTS.SET_TWEET_REPLY, selectedTweetId => {
      this.selectedTweetId = selectedTweetId;
    });
  },
  mounted() {
    this.addScrollListener();
    this.showTour();
    this.$nextTick(() => this.openDraft());
  },
  beforeDestroy() {
    this.removeBusListeners();
    this.removeScrollListener();
  },
  methods: {
    onScrollToMessage() {
      this.$nextTick(() => {
        retryUntilSuccess(() => {
          const unreadBadge = document.getElementById('unread_toast');
          if (unreadBadge) {
            unreadBadge.scrollIntoView({ behavior: 'smooth' });
          } else {
            this.scrollToBottom();
          }
        });
      });

      setTimeout(() => {
        this.makeMessagesRead();
      }, 4000);
    },
    removeBusListeners() {
      bus.$off(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
    },
    onTogglePrivateReply(show) {
      this.showPrivateReplyModal = show;
    },
    assignAgent() {
      if (!this.currentChat.meta.assignee) {
        this.$store
          .dispatch('assignAgent', {
            conversationId: this.currentChat.id,
            agentId: this.currentUserId,
          })
          .then(() => {
            this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'), 'success');
          });
      }
    },
    showTour() {
      if (this.$tours.chatSection)
        setTimeout(() => {
          this.$tours.chatSection.start();
        }, 1000);
    },
    addScrollListener() {
      this.conversationPanel = this.$el.querySelector('.conversation-panel');
      this.setScrollParams();
      this.conversationPanel.addEventListener(
        'scroll',
        debounce(this.handleScroll, 500)
      );
      this.$nextTick(() => this.scrollToBottom());
      this.isLoadingPrevious = false;
    },
    removeScrollListener() {
      this.conversationPanel.removeEventListener('scroll', this.handleScroll);
    },
    scrollToBottom() {
      this.conversationPanel.scrollTo({
        top: this.conversationPanel.scrollHeight,
        behavior: 'smooth',
      });
    },
    setScrollParams() {
      this.heightBeforeLoad = this.conversationPanel.scrollHeight;
      this.scrollTopBeforeLoad = this.conversationPanel.scrollTop;
    },
    handleScroll(e) {
      this.setScrollParams();

      if (e.target.scrollTop < 100 && this.shouldLoadMoreChats) {
        this.isLoadingPrevious = true;
        this.$store
          .dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.getMessages.messages[0].id,
          })
          .then(() => {
            const heightDifference =
              this.conversationPanel.scrollHeight - this.heightBeforeLoad;
            this.conversationPanel.scrollTop =
              this.scrollTopBeforeLoad + heightDifference;
            this.isLoadingPrevious = false;
            this.setScrollParams();
          });
      }
      // if (!this.lastScrollPosition) this.showScrollButton = false;
      if (Math.abs(e.target.scrollTop - this.lastScrollPosition) > 100) {
        this.showScrollButton = e.target.scrollTop < this.lastScrollPosition;
        this.lastScrollPosition = e.target.scrollTop;
      }
    },
    toggleReplySelection(val) {
      this.replyShowState = val;
    },
    openDraft() {
      if (
        !this.showReplyBox &&
        this.isAnEmailChannel &&
        this.getDraft(this.currentChat.id)
      ) {
        this.showReplyBox = true;
      }
    },
    getAgentInfo(id) {
      return this.$store.getters['agents/getAgent'](id);
    },
    findParticipant(id) {
      let combinedContacts = [];

      const {
        meta: { additional_contacts: contacts },
      } = this.getMessages;

      if (contacts?.length) {
        combinedContacts = [...contacts, this.currentContact];
      } else combinedContacts = [this.currentContact];

      const foundContact = combinedContacts?.find(contact => contact.id === id);

      return foundContact || { name: '', instagram_username: '' };
    },
    getParticipantName(id, type) {
      let recepientName = '';
      if (!id) return { name: recepientName };

      switch (type?.toLowerCase()) {
        case MESSAGE_SENDER_TYPE.CONTACT:
          recepientName = this.findParticipant(id).name;
          break;
        case MESSAGE_SENDER_TYPE.AGENT_BOT:
          recepientName = 'Agent Bot';
          break;
        case MESSAGE_SENDER_TYPE.OUTBOUND_BOT:
          recepientName = 'Outbound Bot';
          break;
        case MESSAGE_SENDER_TYPE.USER:
          recepientName = this.getAgentInfo(id).name;
          break;
        default:
          recepientName = 'System';
      }
      return { name: recepientName };
    },
    getReplyData(data) {
      const defaultPayload = {
        parentMessageSourceId: null,
        parentMessageContent: '',
        sourceId: null,
        content: '',
        replierName: '',
        recipientName: '',
        messageType: null,
      };

      if (!this.hasReplyMessageSupport) return defaultPayload;

      const {
        message_type,
        content_attributes,
        content,
        source_id,
        sender,
        parent_message_id,
        attachments = [],
      } = data;

      const isMessageTypeValid = [
        MESSAGE_TYPE.INCOMING,
        MESSAGE_TYPE.OUTGOING,
      ].includes(message_type);

      if (
        !isMessageTypeValid &&
        (parent_message_id === null || parent_message_id === undefined)
      )
        return defaultPayload;

      const replierName = this.getParticipantName(sender?.id, sender?.type)
        .name;

      const instagramMention =
        this.findParticipant(sender?.id).instagram_username || '';

      const recipientName = this.getParticipantName(
        content_attributes?.parent_message_sender_id || '',
        content_attributes?.parent_message_sender_type || ''
      ).name;

      const isIncoming = MESSAGE_TYPE.INCOMING === message_type;
      // do not show parent message content for facebook comment incoming message
      const incomingFacebookComment = isIncoming && this.isAFacebookComment;

      const payload = {
        ...defaultPayload,
        parentMessageSourceId: parent_message_id,
        parentMessageContent: incomingFacebookComment
          ? ''
          : content_attributes?.parent_message_content || '',
        sourceId: source_id,
        content,
        attachments,
        instagramMention:
          this.isInstagramComment && instagramMention
            ? `@${instagramMention} `
            : '',
        replierName,
        recipientName,
        hasPrivateReplyAlready: content_attributes?.private_reply || '',
        messageType: message_type,
      };

      return ({ showOnReplyBox = false, showPrivateReply = false } = {}) => {
        if (showOnReplyBox) {
          this.selectedMessageForReply = data;

          this.toggleReplySelection(false);
          this.$nextTick(() => this.toggleReplySelection(true));

          this.focusReplyBox();
        }

        if (showPrivateReply) {
          this.selectedMessageforPrivateReply = data;
          this.onTogglePrivateReply(true);
        }

        return { payload };
      };
    },
    getReplyDataDep(data) {
      const payload = {
        senderName: '',
        senderMessage: '',
        type: '',
      };

      const { message_type, content_attributes } = data;

      if (![0, 1].includes(message_type)) return payload;

      if (!content_attributes?.['in_reply_to']) return payload;

      const { messages = [] } = this.getMessages;
      const inReplyToId = content_attributes.in_reply_to;

      const [selectedMessage = {}] = messages.filter(
        message =>
          message.source_id === inReplyToId ||
          message.ums_message_id === inReplyToId
      );

      const { attachments, content } = selectedMessage;

      if (attachments && attachments.length) {
        payload.senderMessage = attachments[0].data_url;
        payload.type = attachments[0].file_type;
      } else if (content !== null) {
        payload.senderMessage = content;
        payload.type = 'text';
      }

      if (message_type === 0) {
        payload.senderName = this.currentContact.name;
      } else if (message_type === 1) {
        payload.senderName = this.currentChat.meta.assignee?.name ?? '';
      }

      return payload;
    },
    makeMessagesRead() {
      if (this.currentChat.id)
        this.$store.dispatch('markMessagesRead', { id: this.currentChat.id });
    },
    removeTweetSelection() {
      this.selectedTweetId = null;
    },
    getDraft(cid) {
      return JSON.parse(localStorage.getItem('draftMessages'))?.[cid] || '';
    },
    focusReplyBox() {
      this.$refs.replyBox.$refs.messageInput.focus();
    },
    focusToEmailsInput() {
      return document.querySelector('.form-input').focus();
    },
    focusEditor() {
      return this.$nextTick(() =>
        document.querySelector('div.ProseMirror-woot-style').focus()
      );
    },
    async prepareAttachments(fileData) {
      let response = await fetch(fileData.data_url);
      let data = await response.blob();
      const newDate = new Date();
      let metaData = {
        lastModified: newDate.getTime(),
        lastModifiedDate: newDate,
        type: fileData.file_type,
        webkitRelativePath: '',
      };
      let file = new File(
        [data],
        fileData.data_url.substring(fileData.data_url.lastIndexOf('/') + 1),
        metaData
      );

      return Promise.resolve(file);
    },
    sendDataToReplyBox(
      message,
      quotedText,
      to,
      cc,
      bcc,
      callBackFn = () => {}
    ) {
      this.showReplyBox = true;

      this.$nextTick(() => {
        this.$refs.replyBox.toEmails = to;
        this.$refs.replyBox.ccEmails = (cc && cc.join(',')) || '';
        this.$refs.replyBox.bccEmails = (bcc && bcc.join(',')) || '';
        this.$refs.replyBox.message = message || '';
        this.$refs.replyBox.quotedText = quotedText || '';
        this.$refs.replyBox.scrollToBottom();
        callBackFn();
      });
    },
    senderAddress(isIncoming, emailAttributes, sender) {
      if (isIncoming && emailAttributes?.from) return emailAttributes.from[0];
      return sender?.email || this.inbox.email;
    },
    timeStamp(emailAttributes, replyTimeStamp) {
      const date = new Date(emailAttributes?.date || replyTimeStamp * 1000);

      const formattedDate = new Intl.DateTimeFormat('en-US', OPTIONS).format(
        date
      );

      return `${formattedDate}`;
    },
    generateForwardContent(data) {
      if (!data || Object.keys(data).length === 0) return '';

      const senderEmail = this.senderAddress(
        [1, 10].includes(data.message_type),
        data.content_attributes.email,
        data.sender
      );

      const timeStamp = this.timeStamp(
        data.content_attributes.email,
        data.created_at || 0
      );

      let toEmails;
      let ccEmails;
      let bccEmails;

      if ('email' in data.content_attributes) {
        toEmails = data.content_attributes.email.to.join(', ');
        ccEmails = data.content_attributes.email.cc?.join(', ');
        bccEmails = data.content_attributes.email.bcc?.join(', ');
      } else {
        toEmails = data.content_attributes.to_emails;
        ccEmails = data.content_attributes.cc_email?.join(', ');
        bccEmails = data.content_attributes.bcc_email?.join(', ');
      }

      const emailSubject =
        data.content_attributes.email?.subject || this.mailSubject;

      const prependMetaContent = `\\\n\\\n\\\n---------- Forwarded message ----------\n\nFrom: ${senderEmail}\n\nDate: ${timeStamp}\n\nSubject: ${emailSubject}\n\nTo: ${toEmails}\n\n${(ccEmails &&
        'Cc: ' + ccEmails) ||
        ''}\n\n${(bccEmails && 'Bcc: ' + bccEmails) || ''}\\\n\\\n\\\n`;

      const {
        html_content: { reply: replyHTMLContent } = {},
        text_content: { reply: replyTextContent } = {},
        verification_content,
      } = data.content_attributes.email || {};

      const { s3_content } = data;

      let contentToBeParsed = '';

      if (s3_content.trim()) contentToBeParsed = s3_content;
      else
        contentToBeParsed =
          replyHTMLContent ??
          replyTextContent ??
          verification_content ??
          data.content;

      if (!contentToBeParsed) return '';

      contentToBeParsed = this.stripStyleCharacters(contentToBeParsed);

      if ([1, 10].includes(data.message_type))
        return prependMetaContent + contentToBeParsed;

      let processedString = turndownService.turndown(contentToBeParsed);
      processedString = processedString.replaceAll('\n', '\\\n');
      processedString = processedString.replaceAll('\\\n\\\n', '\\\n');

      return prependMetaContent + processedString;
    },
    onForwardEmail(data) {
      if (this.privateReplyMode) return;
      this.emailForwardingMode = true;
      this.assignAgent();
      const message = this.generateForwardContent(data).replace(/&gt;+/g, '>');
      let quotedText = this.quotedContent(data);
      quotedText = quotedText ? '\\\n\\\n\\\n*...*\n' + quotedText : '';

      if (data.attachments && data.attachments.length !== 0) {
        Promise.all(
          data.attachments.map(attachment => {
            return this.prepareAttachments(attachment);
          })
        ).then(res => {
          this.sendDataToReplyBox(
            message + quotedText,
            quotedText,
            '',
            '',
            '',
            () => {
              this.focusToEmailsInput();
              this.$refs.replyBox.uploadAttachments(res);
            }
          );
        });
      }
      this.sendDataToReplyBox(
        message + quotedText,
        quotedText,
        '',
        '',
        '',
        () => {
          this.focusToEmailsInput();
        }
      );
    },
    generateQuotedText(data) {
      const quotedContent = data.s3_content
        ? turndownService.turndown(data.s3_content)
        : data.content;
      let quotedText = '\\\n\\\n\\\n*...*';

      if ([0, 1, 9, 10].includes(data.message_type) && quotedContent) {
        const timeStamp = this.timeStamp(
          data.content_attributes?.email,
          data.created_at || 0
        );

        const senderEmail = this.senderAddress(
          data.message_type !== 1,
          data.content_attributes?.email,
          data.sender
        );

        const prependMetaInfo = `\n***On ${timeStamp} <<${senderEmail}>> wrote:***\n\n`;

        quotedText +=
          prependMetaInfo.replaceAll('\n', '\n>') +
          quotedContent?.replaceAll('\\\n', '\n').replaceAll('\n', '\n>') +
          '\n>';

        return quotedText;
      }

      return quotedText;
    },
    quotedContent(data) {
      let quotedParsedContent;
      if ('email' in data.content_attributes) {
        const {
          html_content: { quoted: quotedHTMLContent } = {},
          text_content: { quoted: quotedTextContent } = {},
        } = data.content_attributes.email || {};

        const { s3_quoted_content } = data;

        quotedParsedContent =
          s3_quoted_content ??
          quotedHTMLContent ??
          quotedTextContent ??
          data.content;

        quotedParsedContent =
          quotedParsedContent &&
          turndownService.turndown(quotedParsedContent).replaceAll('\n', '\n>');

        return quotedParsedContent;
      }
      const { content_attributes: { quoted_text: quotedText } = {} } =
        data || {};

      quotedParsedContent = quotedText?.replaceAll('\n', '\n>');
      return quotedParsedContent || '';
    },
    onReply(data) {
      if (this.privateReplyMode) return;

      this.emailForwardingMode = false;
      this.assignAgent();
      let toMails;

      if (data.message_type === MESSAGE_TYPE.INCOMING)
        toMails = data.content_attributes.email.from[0];

      const isCSATOrWaitTime = [
        MESSAGE_CONTENT_TYPE.CSAT,
        MESSAGE_CONTENT_TYPE.WAIT_TIME,
      ].includes(data.content_type);

      if (!isCSATOrWaitTime) {
        switch (data.message_type) {
          case MESSAGE_TYPE.OUTGOING:
            toMails = data.content_attributes.to;
            break;
          case MESSAGE_TYPE.OUT_OF_OFFICE:
          case MESSAGE_TYPE.FORWARDED:
            toMails = data.content_attributes.to_emails
              .split(',')
              .find(emailId => emailId !== this.inbox.email);
            break;
          default:
            break;
        }
      }

      if (data.message_type === MESSAGE_TYPE.OUTGOING && isCSATOrWaitTime)
        toMails = data.content_attributes.to;

      const quotedText =
        this.generateQuotedText(data) + this.quotedContent(data);
      this.sendDataToReplyBox(
        quotedText,
        quotedText,
        toMails,
        '',
        '',
        this.focusEditor
      );
    },
    onReplyToAll(data) {
      if (this.privateReplyMode) return;

      this.emailForwardingMode = false;
      this.assignAgent();

      let toMails;
      let ccEmails;
      let partionedCcEmails;
      let bccEmails;

      if (data.message_type === MESSAGE_TYPE.INCOMING) {
        ccEmails = data.content_attributes.email.cc || [];
        toMails = data.content_attributes.email.from[0];
        partionedCcEmails = data.content_attributes.email.to.filter(
          emailId => emailId !== this.inbox.email
        );
        ccEmails = [...ccEmails, ...partionedCcEmails];
        bccEmails = data.content_attributes.email.bcc;
      }

      if (
        data.message_type === MESSAGE_TYPE.OUTGOING ||
        data.message_type === MESSAGE_TYPE.FORWARDED
      ) {
        ccEmails = data.content_attributes?.cc_emails || [];
        [
          toMails,
          ...partionedCcEmails
        ] = data.content_attributes.to_emails.split(',');
        ccEmails = [...ccEmails, ...partionedCcEmails];
        bccEmails = data.content_attributes.bcc_emails;
      }

      const quotedText =
        this.generateQuotedText(data) + this.quotedContent(data);
      this.sendDataToReplyBox(
        quotedText,
        quotedText,
        toMails,
        ccEmails,
        bccEmails,
        this.focusEditor
      );
    },
    onPrivateReply() {
      this.privateReplyMode = true;
      this.showReplyBox = true;
      this.$nextTick(() => {
        this.$refs.replyBox.setPrivateReplyMode();
        this.$nextTick(() => this.scrollToBottom());
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.banner {
  color: var(--white);
  font-size: var(--font-size-mini);
  padding: var(--space-slab) var(--space-normal);
  text-align: center;
  position: relative;

  a {
    text-decoration: underline;
    color: var(--white);
    font-size: var(--font-size-mini);
  }

  &.messenger-policy {
    &--banner {
      background: var(--r-400);
    }

    &--info {
      background: var(--b-400);
    }
  }

  .banner-close-button {
    cursor: pointer;
    margin-left: var(--space--two);
    color: var(--white);
  }
}

.spinner--container {
  min-height: var(--space-jumbo);
}

.facebook-responsive {
  height: 300px;
  max-width: unset;
  margin: 0 1.6rem;
  padding: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 1rem;
  max-width: 400px;
  border-bottom-left-radius: 0;
}

// reply box animation
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s ease-in;
}

.slide-fade-enter-active {
  transition-delay: 0.2s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY($space-four);
  opacity: $zero;
}

.slide-fade-enter-to,
.slide-fade-leave {
  opacity: 1;
}

.highlight-message {
  animation: highlight-fade 4s ease-in-out;
}

@keyframes highlight-fade {
  0% {
    background-color: $neutral-grey-400;
  }
  50% {
    background-color: $neutral-grey-400;
  }
  100% {
    background-color: transparent;
  }
}
</style>
